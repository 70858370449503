import React from "react"
import { Row, Container } from "react-bootstrap"
import { makeLink } from "../utils/google-pic-format"
import InnerWrap from "../components/_Wrap/inner-wrap"
import Hero from "../components/Hero"

import WithPicture from "../components/Paragraph/WithPicture"
import SEO from "../components/seo"
import CTA from "../components/CTA"

const StudioRental = ({ data }) => {
  const { content, header } = data
  return (
    <React.Fragment>
      <SEO
        title="Brazee Studio Rental"
        keywords={[`brazee`, `art studio`, `studio rental`, `glass studio`]}
      />
      <Hero src={makeLink(header.edges[0].node.headerPicture)}></Hero>

      <Container fluid>
        <Row>
          <InnerWrap>
            <WithPicture
              title={header.edges[0].node.sitetitle}
              subheading={header.edges[0].node.title}
              src={makeLink(content.edges[0].node.image)}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: content.edges[0].node.content.replace(/\n/g, "<br/>"),
                }}
              ></p>
            </WithPicture>
          </InnerWrap>
        </Row>
        <Row>
          <CTA />
        </Row>
      </Container>
      <div style={{ height: "50px", backgroundColor: "white" }}></div>
    </React.Fragment>
  )
}
export const StudioRentalQuery = graphql`
  query RentalQuery {
    header: allGoogleSheetStudioRentalsAndArtistsRow {
      edges {
        node {
          sitetitle
          headerPicture
          content
        }
      }
    }
    content: allGoogleSheetStudioRentalsAndArtistsRow {
      edges {
        node {
          title
          content
          image
        }
      }
    }
  }
`

export default StudioRental
